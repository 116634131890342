import layout from "./Layout"

export default class Viewport {
  winW: number | undefined

  constructor() {
    this.init()
  }

  resize(): void {
    if (this.winW !== layout.window.width) {
      this.winW = layout.window.width
      this.setup()
    }
  }

  setup(): void {
    const h: number = layout.isIos ? window.screen.height : window.innerHeight
    document.documentElement.style.setProperty(
      "--v-width",
      `${window.innerWidth}px`
    )
    document.documentElement.style.setProperty("--v-height", `${h}px`)
  }

  init(): void {
    this.winW = layout.window.width
    this.setup()
  }
}
