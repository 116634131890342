import { gsap } from "gsap"
import { tlProp, expo } from "../helpers/animation"

interface AccordionsDOM {
  accordions: NodeListOf<HTMLElement>
}

interface AccordionGroupDOM {
  container: Element
  groups: NodeListOf<HTMLElement>
}

interface AccordionDOM {
  container: HTMLElement | undefined | null
  head?: HTMLElement | undefined | null
  content?: HTMLElement | undefined | null
  innerContent?: HTMLElement | undefined | null
  separator?: HTMLElement | undefined | null
}

export default class Accordions {
  instances: AccordionGroup[] = []
  DOM?: AccordionsDOM

  constructor() {
    this.init()
  }

  setup() {
    if (this.DOM && this.DOM.accordions.length) {
      for (let i = 0; i < this.DOM.accordions.length; i++) {
        this.instances.push(new AccordionGroup(this.DOM.accordions[i]))
      }
    }
  }

  init(container = document) {
    const accordions = container.querySelectorAll(".js-accordions")

    if (accordions !== undefined && accordions.length) {
      this.DOM = {
        accordions: accordions as NodeListOf<HTMLElement>,
      }
      this.instances = []

      this.setup()
    }
  }
}

export class AccordionGroup {
  items: Accordion[] = []
  openMenu: Accordion | null = null
  DOM?: AccordionGroupDOM

  constructor(container: HTMLElement) {
    this.init(container)
  }

  setup() {
    if (this.DOM && this.DOM.groups.length) {
      for (let i = 0; i < this.DOM.groups.length; i++) {
        this.items.push(new Accordion(this.DOM.groups[i], this))
      }
    }
  }

  setOpen = (value: Accordion | null) => {
    this.openMenu = value
  }

  init(container: HTMLElement) {
    this.DOM = {
      container,
      groups: container.querySelectorAll(".js-accordion"),
    }

    this.items = []
    this.openMenu = null

    this.setup()
  }
}

class Accordion {
  DOM?: AccordionDOM
  group?: AccordionGroup
  isOpen?: boolean
  initialOpen?: boolean

  constructor(container: HTMLElement, group: AccordionGroup) {
    this.init(container, group)
  }

  setup() {
    if (this.DOM) {
      if (this.DOM.head) {
        this.DOM.head.addEventListener("click", this.toggle)
      }

      if (this.DOM.innerContent) {
        gsap.set(this.DOM.innerContent, { y: -30, opacity: 0 })
      }

      if (this.initialOpen) {
        this.open(true)
      }
    }
  }

  toggle = () => {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  open = (immediate = false) => {
    if (!this.isOpen) {
      this.isOpen = true

      if (this.DOM?.container) {
        this.DOM.container.classList.add("is-open")
      }

      if (this.group) {
        this.group.openMenu && this.group.openMenu.close()
        this.group.setOpen(this)
      }

      if (this.DOM?.content) {
        gsap.to(this.DOM.content, {
          height: "auto",
          duration: tlProp(0.55, immediate),
          overwrite: true,
          ease: expo,
        })
      }

      if (this.DOM?.separator) {
        gsap.to(this.DOM?.separator, {
          height: 20,
          duration: tlProp(0.55, immediate),
          overwrite: true,
          ease: expo,
        })
      }

      if (this.DOM?.innerContent) {
        gsap.to(this.DOM.innerContent, {
          y: 0,
          opacity: 1,
          overwrite: true,
          duration: tlProp(0.42, immediate),
          ease: expo,
        })
      }
    }
  }

  close = () => {
    if (this.isOpen) {
      this.isOpen = false

      if (this.DOM?.container) {
        this.DOM.container.classList.remove("is-open")
      }

      if (this.group) {
        this.group.setOpen(null)
      }

      if (this.DOM?.content) {
        gsap.to(this.DOM.content, {
          height: 0,
          duration: 0.55,
          overwrite: true,
          ease: expo,
          onComplete: () => {
            if (this.DOM?.innerContent) {
              gsap.set(this.DOM.innerContent, {
                y: 30,
                opacity: 0,
                overwrite: true,
              })
            }
          },
        })
      }

      if (this.DOM?.separator) {
        gsap.to(this.DOM?.separator, {
          height: 0,
          duration: 0.55,
          overwrite: true,
          ease: expo,
        })
      }
    }
  }

  init(container: HTMLElement, group: AccordionGroup) {
    this.DOM = {
      container: container,
      head: container.querySelector<HTMLElement>(".js-accordion-head"),
      content: container.querySelector<HTMLElement>(".js-accordion-content"),
      innerContent: container.querySelector<HTMLElement>(
        ".js-accordion-inner-content"
      ),
      separator: container.querySelector<HTMLElement>(
        ".js-accordion-separator"
      ),
    }

    this.group = group
    this.isOpen = false
    this.initialOpen = Boolean(this.DOM?.container?.dataset.open)

    this.setup()
  }
}
