import Accordions from "./Accordions"
import HashScroll from "./HashScroll"
import layout from "./Layout"
import MarqueeManager from "./MarqueeManager"
import Viewport from "./Viewport"

// helpers
import debounce from "../helpers/debounce"

class Application {
  hashScroll: HashScroll | undefined
  marqueeManager: MarqueeManager | undefined
  viewport: Viewport | undefined
  accordions: Accordions | undefined

  start() {
    document.addEventListener("DOMContentLoaded", () => {
      document.documentElement.className = "js"
    })

    window.addEventListener("load", () => {
      layout.init()

      this.viewport = new Viewport()
      this.hashScroll = new HashScroll()
      this.marqueeManager = new MarqueeManager()
      this.accordions = new Accordions()
    })

    window.addEventListener("resize", debounce(this.onResize, 150))
    window.addEventListener("orientationchange", debounce(this.onResize, 150))
  }

  onResize = () => {
    layout.resize()
    this.viewport?.resize()
  }
}

const application = new Application()

export default application
