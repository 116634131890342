class Layout {
  isIos: boolean
  isSafari: boolean
  window: { height: number; width: number }
  device: number

  constructor() {
    this.isIos = false
    this.isSafari = false
    this.window = { height: 0, width: 0 }
    this.device = 0
  }

  detectApple(): void {
    this.isIos =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  }

  createRuler(): void {
    const ruler: HTMLDivElement = document.createElement("div")

    ruler.style.position = "fixed"
    ruler.style.height = "100%"
    ruler.style.width = "0"
    ruler.style.top = "0"

    document.documentElement.appendChild(ruler)
    this.window.height = this.isIos ? ruler.offsetHeight : window.innerHeight

    document.documentElement.removeChild(ruler)
  }

  getWindow(): void {
    this.createRuler()
    this.window.width = window.innerWidth
  }

  getDevice(): void {
    this.device = window.matchMedia("(min-width:1920px)").matches
      ? 3
      : window.matchMedia("(min-width:1024px)").matches
      ? 2
      : window.matchMedia("(min-width:768px)").matches
      ? 1
      : 0
  }

  isTouch(): boolean {
    return document.body.classList.contains("is-touch")
  }

  setup(): void {
    this.detectApple()
    this.getDevice()
    this.getWindow()
  }

  init(): void {
    this.setup()
  }

  resize() {
    const cachedW = window.innerWidth
    const cachedH = window.innerHeight

    this.detectApple()
    this.getDevice()

    if (this.window.width !== cachedW) {
      this.window.width = window.innerWidth
    }

    if (this.window.height !== cachedH) {
      this.createRuler()
    }
  }
}

const layout = new Layout()

export default layout
