type DebouncedFunction<T extends (...args: unknown[]) => unknown> = (
  ...args: Parameters<T>
) => ReturnType<T>

const debounce = <T extends (...args: unknown[]) => unknown>(
  fn: T,
  ms = 300
): DebouncedFunction<T> => {
  let timeoutId: ReturnType<typeof setTimeout>
  return function (this: unknown, ...args: Parameters<T>): ReturnType<T> {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn.apply(this, args), ms)
    return undefined as ReturnType<T>
  }
}

export default debounce
