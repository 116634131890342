function scrollToY(targetY = 0) {
  window.scrollTo({
    top: targetY,
    behavior: "smooth",
  })
}

function scrollTo(target: HTMLElement, offset = 0) {
  const box = target.getBoundingClientRect()
  const scrollTop =
    (window.scrollY || document.documentElement.scrollTop) + box.top - offset

  scrollToY(scrollTop)
}

export default class HashScroll {
  DOM?: { hashElements: NodeListOf<HTMLElement> }

  constructor() {
    this.init()
  }

  scrollLinkTo = (e: Event) => {
    e.preventDefault()

    const currentTarget = e.currentTarget as HTMLElement
    const targetId = currentTarget.getAttribute("href")
    const target: HTMLElement | null = document.querySelector(`${targetId}`)

    if (target) {
      scrollTo(target, 150)
    } else {
      console.warn("Target non esiste per l'id: ", targetId)
    }
  }

  setup() {
    if (this.DOM && this.DOM.hashElements.length) {
      for (let i = 0; i < this.DOM.hashElements.length; i++) {
        this.DOM.hashElements[i].addEventListener("click", this.scrollLinkTo)
      }
    }
  }

  init(container = document) {
    const hashElements =
      container.querySelectorAll<HTMLElement>(".js-hash-scroll")

    if (hashElements.length > 0) {
      this.DOM = {
        hashElements,
      }

      this.setup()
    }
  }
}
