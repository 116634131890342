import "../htmx"
import htmx from "htmx.org"
import "htmx-ext-response-targets"
import "./components/form"

import application from "./components/Application"

declare global {
  interface Window {
    htmx: typeof htmx
    MSStream?: unknown
  }
}

// https://github.com/bigskysoftware/htmx/issues/2587
htmx.config.historyCacheSize = 0
htmx.config.scrollIntoViewOnBoost = false
htmx.config.defaultSwapStyle = "outerHTML"

application.start()
