import gsap from "gsap"

import layout from "./Layout"
import { horizontalLoop } from "../helpers/animation"

type MarqueeOptions = {
  container: HTMLElement
  config: {
    repeat?: number
    speed?: number
    reversed?: boolean
  }
}

interface DOM {
  container: HTMLElement
  items: NodeListOf<Element>
}

class Marquee {
  DOM?: DOM
  config: MarqueeOptions["config"] | undefined
  loop: gsap.TweenTarget | undefined
  destroy() {
    throw new Error("Destroy method not implemented.")
  }

  constructor(options: MarqueeOptions) {
    this.init(options)
  }

  start() {
    this.loop = horizontalLoop(this.DOM?.items, {
      paused: false,
      repeat: this.config?.repeat,
      speed: this.config?.speed,
      reversed: this.config?.reversed,
      paddingRight: 40,
    })

    this.DOM?.container.addEventListener("mouseenter", this.onMouseEnter)
    this.DOM?.container.addEventListener("mouseleave", this.onMouseLeave)
  }

  onMouseEnter = () => {
    if (this.loop && layout.device >= 2) {
      gsap.to(this.loop, { timeScale: 0, overwrite: true })
    }
  }

  onMouseLeave = () => {
    if (this.loop && layout.device >= 2) {
      gsap.to(this.loop, {
        timeScale: this.config?.reversed ? -1 : 1,
        overwrite: true,
      })
    }
  }

  init(options: MarqueeOptions) {
    this.DOM = {
      container: options.container,
      items: options.container.querySelectorAll(".js-marquee-item"),
    }

    this.config = {
      repeat: options.config.repeat || -1,
      speed: options.config.speed || 1.6,
      reversed: options.config.reversed || false,
    }

    this.start()
  }
}

export default Marquee
